import React from 'react'
import Insurance from "../../static/images/insurance.jpg"
import biz from "../../static/images/biz.jpg"
import make from "../../static/images/make.jpg"
import tech from "../../static/images/tech.jpg"
import acturial from "../../static/images/actuarial.jpg"

export default function mainBlogPage() {
  return (
    <div>
        
        <section class=" px-10 py-10 lg:py-20 lg:px-20 bg-white" style={{fontFamily:'poppins'}}>
  <div class="container lg:px-20 mx-auto">
    <div class="flex flex-wrap -mx-4 -mb-16">
      <div class="w-full lg:w-2/3 px-4 mb-16">
        <div class="flex h-96 mb-10">
          <img class="lg:w-full lg:h-full object-cover rounded-xl" src={Insurance} alt=""/>
        </div>
        <span class="inline-block mb-4 text-xs text-indigo-800">Published On May 13, 2022 | Raunak Jha</span>
        <h2 class="mb-4 text-xl lg:text-2xl font-semibold font-heading text-indigo-800">Parametric Insurance – Role of Actuaries</h2>
        <p class="mb-4 text-md  lg:text-md text-gray-500">Parametric insurance also known as Index based insurance, is an emerging and non-traditional way of transferring risks.</p>
        <a class="text-lg font-medium text-indigo-800 underline hover:no-underline" href="/blogs/roleofactuaries">Read more</a>
      </div>
      <div class="w-full lg:w-1/3 px-4 mb-16">
        <div class="flex h-96 mb-10">
          <img class="w-full h-full object-cover rounded-xl" src={acturial} alt=""/>
        </div>
        <span class="inline-block mb-4 text-xs text-indigo-800">Published On July 8, 2022 | Vikram Arora</span>
        <h2 class="mb-4 text-xl lg:text-2xl font-semibold font-heading text-indigo-800">Underwriting Scoring Models</h2>
        <p class="mb-4 text-md  lg:text-md text-gray-500">Insurance is a competitive business and Underwriting plays an important role for insurers to stay ahead in the market.</p>
        <a class="text-lg font-medium text-indigo-800 underline hover:no-underline" href="/blogs/UnderwritingScoringModel">Read more</a>
      </div>
      <div class="w-full lg:w-1/3 px-4 mb-16">
        <div class="flex h-96 mb-10">
          <img class="w-full h-full object-cover rounded-xl" src={biz} alt=""/>
        </div>
        <span class="inline-block mb-4 text-xs text-indigo-800">Published On June 7, 2022 | Tamanna Goel</span>
        <h2 class="mb-2 text-xl lg:text-2xl font-semibold font-heading text-indigo-800">Underwriting – An Introduction from Actuarial Perspective</h2>
        <p class="mb-4 text-md  lg:text-md text-gray-500">Underwriting is an integral part of the overall premium setting cycle for any insurance company.</p>
        <a class="text-lg font-medium text-indigo-800 underline hover:no-underline" href="/blogs/ActuarialPerspective/">Read more</a>
      </div>
      <div class="w-full lg:w-1/3 px-4 mb-16">
        <div class="flex h-96 mb-10">
          <img class="w-full h-full object-cover rounded-xl" src={tech} alt=""/>
        </div>
        <span class="inline-block mb-4 text-xs text-indigo-800">Published On November 30, 2021 | Raunak Jha</span>
        <h2 class="mb-4 text-xl lg:text-2xl font-semibold font-heading text-indigo-800">Looking at ERM from the perspective of a STARTUP</h2>
        <p class="mb-4 text-md  lg:text-md text-gray-500">Risks are an inevitable part of entrepreneurship and a structured approach to managing these risks...</p>
        <a class="text-lg font-medium text-indigo-800 underline hover:no-underline" href="/blogs/EnterpriseRisk">Read more</a>
      </div>
      <div class="w-full lg:w-1/3 px-4 mb-16">
        <div class="flex h-96 mb-10">
          <img class="w-full h-full object-cover rounded-xl" src={make} alt=""/>
        </div>
        <span class="inline-block mb-4 text-xs text-indigo-800">Published On November 25, 2021 | Raunak Jha</span>
        <h2 class="mb-4 text-xl lg:text-2xl font-semibold font-heading text-indigo-800 ">How HR strategy is evolving in the space of Employee Health and Welness</h2>
        <p class="mb-4 text-md lg:text-md text-gray-500">In the fast changing and fast paced global workplace, where maintaining competitive advantage is paramount to success,</p>
        <a class="text-lg font-medium text-indigo-800 underline hover:no-underline" href="/blogs/HrStrategy">Read more</a>
      </div>
    </div>
  </div>
</section>






    </div>
  )
}
