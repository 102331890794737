import React from 'react'
import MainBlogPage from '../components/mainBlogPage'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

export default function blogs() {
  return (
    <div>
        <Navbar/>
        <MainBlogPage/>
        <Footer/>
    </div>
  )
}
